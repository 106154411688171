import React, {useEffect, useState} from "react";
import * as CONSTANTS from '../../constants'
import { scrollTo } from "utils";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {green} from "@material-ui/core/colors";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    previewButton:{
        backgroundColor: green[500],
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: green[700],
        },
    }
});

function previewButton(classes, addFilter){
    if(!addFilter) {
        return <Button variant="contained" size="large" color="primary" startIcon={<VisibilityIcon />} disabled >
            Preview
        </Button>
    } else {
        return <Button variant="contained"
                       size="large"
                       color="primary"
                       className={classes.previewButton}
                       startIcon={<VisibilityIcon/>}
                       onClick={() => console.log('click')}>
            Preview
        </Button>
    }
}

function updateState(event, setAddFilter, setter, extSetter, uploadedImg, booleanList){
    setter(event.target.checked);
    extSetter(event.target.checked);
    const tmpList = booleanList.concat([event.target.checked])
    if(uploadedImg) {
        setAddFilter(tmpList.some(e => e))
        console.log(tmpList.some(e => e))
    }
}

const AdvencedPanel = (props) => {
    const classes = useStyles();

    const [addFilter, setAddFilter] = useState(false);
    const [enableGrayscale, setEnableGrayscale] = useState(false);
    const [enableEqualization, setEnableEqualization] = useState(false);
    const [enableSharpening, setEnableSharpening] = useState(false);

    const uploadedImg = props.uploadedImg;
    const executePreview = props.executePreview;
    const extSetEnableGrayscale = props.extSetEnableGrayscale;
    const extSetEnableEqualization = props.extSetEnableEqualization;
    const extSetEnableSharpening = props.extSetEnableSharpening;

    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Advanced:
            </Typography>

            <div className={classes.root}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox onChange={(event) => {
                                updateState(event, setAddFilter, setEnableEqualization, extSetEnableEqualization, uploadedImg, [enableGrayscale, enableSharpening]);
                                executePreview(uploadedImg, enableGrayscale, event.target.checked, enableSharpening);
                            }}/>}
                            label="Equalize"
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography color="textSecondary">
                            This filter equalizes the color distribution in the image. Great to apply on shady/dark images
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox onChange={(event) => {
                                updateState(event, setAddFilter, setEnableGrayscale, extSetEnableGrayscale, uploadedImg, [enableEqualization, enableSharpening]);
                                executePreview(uploadedImg, event.target.checked, enableEqualization, enableSharpening);
                            }}/>}
                            label="Black / White"
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography color="textSecondary">
                            Add a Black-and-White effect to the image
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox onChange={(event) => {
                                updateState(event, setAddFilter, setEnableSharpening, extSetEnableSharpening, uploadedImg, [enableGrayscale, enableEqualization]);
                                executePreview(uploadedImg,enableGrayscale, enableEqualization, event.target.checked);
                            }}/>}
                            label="Sharpen"
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography color="textSecondary">
                            This filter enhances apparent sharpness of the image
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>

        </div>
    )
}

export default AdvencedPanel;