import React, { useEffect } from "react";
import { scrollTo } from "utils";
import Footer from "../Footer";

import Service3 from "../../sections/Services3";
import Service4 from "../../sections/Services4";
import Service5 from "../../sections/Services5";
import Service6 from "../../sections/Services6";
import Service7 from "../../sections/Services7";
import Testimonial3 from "../../sections/Testimonial3";
import CallToAction2 from "../../sections/CallToAction2";
import Pricing1 from "../../sections/Pricing1";
import Contact1 from "../../sections/Contact1";

import TopBar from "../TopBar";
// import TopBar from "../sections/TopBar";
import ImageSizeConvertorWidget from "../ImageSizeConvertorWidget"

const HomePage = () => {
  useEffect(() => {
    scrollTo("root");
  }, [scrollTo]);

  return (
    <div className="landing">
      <TopBar />
      <ImageSizeConvertorWidget />
      {/*<Service3 />*/}
      {/*<Service4 />*/}
      {/*<Service5 />*/}
      {/*<Service6 />*/}
      {/*<Service7 />*/}
      {/*<Testimonial3 />*/}
      {/*<CallToAction2 />*/}
      {/*<Pricing1 />*/}
      {/*<Contact1 />*/}
      <Footer />
    </div>
  );
};

export default HomePage;
