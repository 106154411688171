import React, { useState } from "react";
import { base_url } from '../../constants'
import { Grid, Icon, Button, TextField, Box, Slider, Paper } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import FileUploadComp from './FileUploadComp'
import AdvencedPanel from "./AdvencedPanel";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import Skeleton from '@material-ui/lab/Skeleton';
import * as CONSTANTS from "../../constants";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  introWrapper: {
    padding: "5rem 0px !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0 !important",
      textAlign: "center",
      "& .list": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
  },
  title: {
    textShadow: "0 4px 4px rgba(0, 0, 0, 0.22)",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  cardRoot: {
    maxWidth: 645,
  },
  media: {
    maxHeight: 240,
    width: 550,
    alignItems: "center",
    alignContent: "center"
  },
}));

const TutorialWidget = () => {
  const classes = useStyles();

  return (
    <section className="section" id="intro3">
      <div className={classes.introWrapper}>
        <div className="container">
          <Typography variant="h1" gutterBottom align="center">
            Tutorial
          </Typography>
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="h2" gutterBottom>
                Step 1: Upload an image
              </Typography>
              <Typography variant="body1" gutterBottom>
                You can simply drag and drop your image or click anywhere in the blue area and choose the image you
                want to upload.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div>
                <img src="/assets/images/ISC_Tutorial/upload.JPG" alt="Upload an image" width="100%" height="100%" />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="h2" gutterBottom>
                Step 2: Set the desired dimensions
              </Typography>
              <Typography variant="body1" gutterBottom>
                You can reduce the size of your image by % by moving the white circle left or right.
                When you hover the white circle, a small icon presents the exact proportions you chose.
                <br/>
                It is possible to set your desired dimensions manually by typing the desired width and height you choose.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div>
                <img src="/assets/images/ISC_Tutorial/resize_bar.JPG" alt="Resize Bar" width="100%" height="100%" />
              </div>
            </Grid>


            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography variant="h2" gutterBottom>
                Step 3: Download the Converted Image
              </Typography>
              <Typography variant="body1" gutterBottom>
                By clicking the Download button the download process start. You will be asked to choose where
                to save the converted image localy in your device.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div>
                <img src="/assets/images/ISC_Tutorial/download_button.JPG" alt="Download button" width="50%" height="50%" />
              </div>
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h2" gutterBottom>
                Advanced Features
              </Typography>
              <Typography variant="body1" gutterBottom>
                The following features are advanced editing features you can apply on your image:
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h3" gutterBottom>
                Equalize
              </Typography>
              <Typography variant="body1" gutterBottom>
                This filter equalizes the color distribution in the image. Great to apply on shady/dark images
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h3" gutterBottom>
                Black/White
              </Typography>
              <Typography variant="body1" gutterBottom>
                Add a Black-and-White effect to the image
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant="h3" gutterBottom>
                Sharpen
              </Typography>
              <Typography variant="body1" gutterBottom>
                AThis filter enhances apparent sharpness of the image
              </Typography>
            </Grid>


          </Grid>
        </div>
      </div>
    </section>
  );
};

export default TutorialWidget;
