import React, { useState } from "react";
import { base_url } from '../../constants'
import { Grid, Icon, Button, TextField, Box, Slider, Paper } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FileUploadComp from './FileUploadComp'
import AdvencedPanel from "./AdvencedPanel";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import Skeleton from '@material-ui/lab/Skeleton';
import * as CONSTANTS from "../../constants";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  introWrapper: {
    padding: "5rem 0px !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0 !important",
      textAlign: "center",
      "& .list": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
  },
  title: {
    textShadow: "0 4px 4px rgba(0, 0, 0, 0.22)",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  downloadButton:{
    backgroundColor: green[500],
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: green[700],
    },
  }
}));

const PrettoSlider = withStyles({
  root: {
    color: '#6c9bea',
    // color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function percentageCalc(orgImgWidth, setImgWidth, orgImgHeight, setImgHeight, sizePrecentage){
  const newWidth = Math.round(orgImgWidth * (sizePrecentage / 100));
  const newHeight = Math.round(orgImgHeight * (sizePrecentage / 100));

  setImgWidth(newWidth);
  setImgHeight(newHeight);
}

function executePreview(filename, setUrl) {
  return function (uploadedImg, enableGrayscale, enableEqualization, enableSharpening) {
    if(!uploadedImg){
      return
    }

    const currUrl = `${base_url}/api/convertAndPreview`

    const data = new FormData();
    data.append('filename', filename);
    data.append('enableGrayscale', enableGrayscale);
    data.append('enableEqualization', enableEqualization);
    data.append('enableSharpening', enableSharpening);

    fetch(currUrl, {
      method: 'POST',
      body: data
    }).then((response) => {
      response.json().then((body) => {
        console.log(body);
        const imgUrl = `${base_url}/api/display/${body.fileName}`
        console.log(imgUrl);
        setUrl(imgUrl);
      });
    });
  }
}

function executeDownload(filename, width, height, enableGrayscale, enableEqualization, enableSharpening){
      // const currUrl = "`${base_url}`/api/convert/" + filename + "/" + width + "/" + height
      const currUrl = `${base_url}/api/convert`

      const data = new FormData();
      data.append('filename', filename);
      data.append('width', width);
      data.append('height', height);
      data.append('enableGrayscale', enableGrayscale);
      data.append('enableEqualization', enableEqualization);
      data.append('enableSharpening', enableSharpening);

      fetch(currUrl, {
        method: 'POST',
        body: data
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `output.jpg`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
}

const ImageSizeConvertorWidget = () => {
  const classes = useStyles();

  const [orgImgWidth, setOrgImgWidth] = useState('');
  const [orgImgHeight, setOrgImgHeight] = useState('');
  const [imgWidth, setImgWidth] = useState('');
  const [imgHeight, setImgHeight] = useState('');

  const [uploadedImg, setUploadedImg] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageFilename, setImageFilename] = useState("");
  const [imageProcessing, setImageProcessing] = useState(false);

  const [enableGrayscale, setEnableGrayscale] = useState(false);
  const [enableEqualization, setEnableEqualization] = useState(false);
  const [enableSharpening, setEnableSharpening] = useState(false);


  return (
    <section className="section" id="intro3">
      <div className={classes.introWrapper}>
        <div className="container">
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item md={6}>


                  <Grid item md={12} xs={12}>

                    <Typography variant="h5" gutterBottom>
                      Upload an image
                    </Typography>

                    <FileUploadComp setOrgWidth={setOrgImgWidth}
                                    setOrgHeight={setOrgImgHeight}
                                    setImgWidth={setImgWidth}
                                    setImageHeight={setImgHeight}
                                    setUploadedImg={setUploadedImg}
                                    setImageUrl={setImageUrl}
                                    setImageFilename={setImageFilename}
                                    setImageProcessing={setImageProcessing}  />
                  </Grid>

                  <Grid item xs={12}>
                    <p></p>
                    <br/>
                  </Grid>

                  <Typography variant="h5" gutterBottom>
                    Resize your image by % or pixels
                  </Typography>

                  <Grid item xs={12}>
                    <PrettoSlider valueLabelDisplay="auto" aria-label="pretto slider" defaultValue={100} onChange={(e, val) => percentageCalc(orgImgWidth, setImgWidth, orgImgHeight, setImgHeight, val)} />
                  </Grid>

                  <Grid container spacing={12} justify="center" alignItems="center">

                    <Grid item xs={6}>
                      <Box boxShadow={0}
                           bgcolor="background.paper"
                           m={1}
                           p={1}
                           style={{ width: '8rem', height: '5rem' }}>
                      <TextField
                          id="filled-helperText"
                          label="Width"
                          defaultValue={imgWidth}
                          variant="outlined"
                          value={imgWidth}
                      />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box boxShadow={0}
                           bgcolor="background.paper"
                           m={1}
                           p={1}
                           style={{ width: '8rem', height: '5rem' }}>
                      <TextField
                          id="filled-helperText"
                          label="Height"
                          defaultValue={imgHeight}
                          variant="outlined"
                          value={imgHeight}
                      />
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box boxShadow={0}
                           bgcolor="background.paper"
                           m={1}
                           p={1}
                           style={{ width: '8rem', height: '5rem' }}>
                        { () => { if(!uploadedImg) {
                          return <Button variant="contained" size="large" color="primary" startIcon={<CloudDownloadIcon />} disabled >
                            Download
                          </Button>
                        } else {
                          return <Button variant="contained"
                                         size="large"
                                         color="primary"
                                         className={classes.downloadButton}
                                         startIcon={<CloudDownloadIcon/>}
                                          onClick={() => executeDownload(imageFilename, imgWidth, imgHeight, enableGrayscale, enableEqualization, enableSharpening)}>
                              Download
                          </Button>
                        }
                          }
                        }
                      </Box>
                    </Grid>


                  </Grid>

              <Grid item xs={12}>
                <AdvencedPanel uploadedImg={uploadedImg}
                               extSetEnableGrayscale={setEnableGrayscale}
                               extSetEnableEqualization={setEnableEqualization}
                               extSetEnableSharpening={setEnableSharpening}
                               executePreview={executePreview(imageFilename, setImageUrl)} />
              </Grid>


            </Grid>
            <Grid item md={6}>
              <div className="flex justify-center items-center max-w-360 mx-auto">
                <Paper variant="outlined">
                { imageProcessing && <Skeleton variant="rect" width={210} height={118} animation="wave" />}
                {uploadedImg &&
                <Box boxShadow={0}
                     bgcolor="background.paper"
                     m={1}
                     p={1}>
                    <img className="w-full"
                         src={imageUrl}
                         alt=""
                    />
                  </Box>
                }
                </Paper>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default ImageSizeConvertorWidget;
