import React from "react";
import { Grid, Button, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  footerSection: {
    "& h4:after": {
      content: '" "',
      position: "absolute",
      bottom: -8,
      left: 0,
      height: 2,
      width: 64,
      background: palette.secondary.main,
    },
  },
}));

const Footer1 = () => {
  const classes = useStyles();

  return (
    <div className={clsx("bg-light-dark", classes.footerSection)} id="footer1">
      <div className="container">
        <Grid container>
          <Grid item lg={6} md={6} sm={12}>
            <div className="p-8 h-full elevation-z3">
              <h4 className="text-20 mb-6 relative">About</h4>
              <p className="text-inherit">
                Resize any image to the exact dimension  or proportion
                you specified and reduce the file size significantly without
                losing quality for FREE, without Photoshop.
              </p>
              {/*<Button variant="contained" color="secondary">*/}
              {/*  Contact Us*/}
              {/*</Button>*/}
            </div>
          </Grid>
          {/*<Grid item lg={3} md={3} sm={12}>*/}
          {/*  <div className="p-8 h-full elevation-z3">*/}
          {/*    <h4 className="text-20 mb-6 relative">Contact</h4>*/}
          {/*    <div className="px-4 my-8 flex items-center mx--4">*/}
          {/*      <Icon className="text-secondary">mail</Icon>*/}
          {/*      <div className="pl-4">*/}
          {/*        <h5 className="m-0 p-0 text-16">Email</h5>*/}
          {/*        <p className="m-0 p-0 text-inherit">email@abc.com</p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="px-4 mt-8 flex items-center mx--4">*/}
          {/*      <Icon className="text-secondary">location_on</Icon>*/}
          {/*      <div className="pl-4">*/}
          {/*        <h5 className="m-0 p-0 text-16">Adress</h5>*/}
          {/*        <p className="m-0 p-0 text-inherit">*/}
          {/*          Topoban, Akhalia Sylhet 3114, BD*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Grid>*/}
          <Grid item lg={6} md={6} sm={12}>
            <div className="p-8 h-full elevation-z3">
              {/*<h4 className="text-20 mb-6 relative">Disclaimer</h4>*/}
              <p className="text-inherit">
                We do NOT keep your images. Each uploaded file is erased within an hour automatically.
                Your privacy is granted. We do not view, copy, share or sell your images.
              </p>

              <div className="mt-8">
                <a href="https://www.linkedin.com/company/imagesizeconvertor/" className="px-2">
                  <img
                    className="h-24 w-24"
                    src="./assets/images/social-linkedin.png"
                    alt="Social Linkedin Icon"
                  />
                </a>
                <a href="https://twitter.com/ImgSizeConvert" className="px-2">
                  <img
                    className="h-24 w-24"
                    src="./assets/images/social-twitter.png"
                    alt="Social Twitter Icon"
                  />
                </a>
                <a href="https://www.facebook.com/Image-Size-Convertor-106431561886381" className="px-2">
                  <img
                    className="h-24 w-24"
                    src="./assets/images/social-facebook.png"
                    alt="Social Facebook Icon"
                  />
                </a>
              </div>
            </div>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <p className="text-inherit">Copyright &copy; 2020 - {new Date().getFullYear()} ImageSizeConvertor</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer1;
