import React, { useEffect } from "react";
import * as CONSTANTS from '../../constants'
import { scrollTo } from "utils";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dropzone from 'react-dropzone'
import {Box, } from "@material-ui/core";
import BackupIcon from "@material-ui/icons/Backup";
import Typography from "@material-ui/core/Typography";


function handleUploadImage(acceptedFiles, setHeight, setWidth, setOrgHeight, setOrgWidth, setUploadedImg, setImageUrl, setImageFilename, setImageProcessing) {
    const file = acceptedFiles[0];
    const fileName = file.name;

    const data = new FormData();
    data.append('file', file);
    data.append('filename', fileName);

    console.log(`${CONSTANTS.base_url}/api/upload`)
    fetch(`${CONSTANTS.base_url}/api/upload`, {
        method: 'POST',
        body: data,
    }).then((response) => {
        response.json().then((body) => {
            console.log(body);
            setOrgWidth(body.imgWidth);
            setOrgHeight(body.imgHeight);
            setWidth(body.imgWidth);
            setHeight(body.imgHeight);
            setUploadedImg(true);
            setImageUrl(`${CONSTANTS.base_url}/api/display/` + fileName);
            setImageFilename(fileName);
            setImageProcessing(false);
        });
    });

    setImageProcessing(true);
}

const FileUploadComp = (props) => {
    useEffect(() => {
        scrollTo("root");
    }, [scrollTo]);

    const setOrgWidth = props.setOrgWidth;
    const setOrgHeight = props.setOrgHeight;
    const setWidth = props.setImgWidth;
    const setHeight = props.setImageHeight;
    const setUploadedImg = props.setUploadedImg;
    const setImageUrl = props.setImageUrl;
    const setImageFilename = props.setImageFilename;
    const setImageProcessing = props.setImageProcessing;

    return (
        <Dropzone onDrop={acceptedFiles => handleUploadImage(acceptedFiles, setHeight, setWidth, setOrgHeight, setOrgWidth, setUploadedImg, setImageUrl, setImageFilename, setImageProcessing)}>
            {({getRootProps, getInputProps}) => (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Box boxShadow={1}
                             bgcolor="background.paper"
                             m={1}
                             p={1}
                             style={{ "background-color": '#6c9bea', color: '#ffffff', textAlign: 'center' }}>
                            <BackupIcon style={{width: '30vw', height: '6vh'}}/>
                            <Typography variant="h5" gutterBottom>
                                Drag and Drop File
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                or Select File to Upload
                            </Typography>
                            {/*</Button>*/}
                        </Box>
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

export default FileUploadComp;
