import React, { useEffect } from "react";
import { scrollTo } from "utils";
import Footer from "../Footer";
import TutorialWidget from "../TutorialWidget";

import Service3 from "../../sections/Services3";
import Service4 from "../../sections/Services4";
import Service5 from "../../sections/Services5";
import Service6 from "../../sections/Services6";
import Service7 from "../../sections/Services7";
import Testimonial3 from "../../sections/Testimonial3";
import CallToAction2 from "../../sections/CallToAction2";
import Pricing1 from "../../sections/Pricing1";
import Contact1 from "../../sections/Contact1";

import TopBar from "../TopBar";
// import TopBar from "../sections/TopBar";

const TutorialPage = () => {
  useEffect(() => {
    scrollTo("root");
  }, [scrollTo]);

  return (
    <div className="landing">
      <TopBar />

      <TutorialWidget/>

      <Footer />
    </div>
  );
};

export default TutorialPage;
